"use client";

import { useState } from "react";
import useProvidersList from "@/hooks/useProvidersList";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import { Box, CircularProgress, Grid, Typography, useMediaQuery } from "@mui/material";
import GameMenu from "./GameMenu";
import DepositAndWithdrawButtonGroup from "./DepositAndWithdrawButtonGroup";
import MainMenu from "./MainMenu";
import Image from "next/image";
import Link from 'next/link';

type Game = {
  category: string;
  name: string;
  code: string;
  status: boolean;
  display: boolean;
  image: string;
  image_custom: string;
  image_newlobby: string;
  image_modify: string;
  is_lobby: boolean;
  games_lobby: any[];
};

const CATEGORY: {
  [key: string]: string;
} = {
  slot: "สล็อต",
  graph: "กราฟ",
  casino: "คาสิโนสด",
  card: "เกมไพ่",
  lottery: "หวย",
  sport: "กีฬา",
};

export default function ProvidersList() {
  const {
    groupedArray,
    isGameListLoading,
    theme,
    handleProviderClick,
    handleRightMenuClick,
    isLoggedIn,
    width,
    height,
    ItemImage,
    isActive,
    activePromotionPlatform,
  } = useProvidersList();
  const [category, setCategory] = useState(0)
  const [config] = useConfigStore((state) => [state.config], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const customAgentList = ['tiga98', 'funee8', 'ehub8', 'rvp8', 'v7heng', 'gax88', 'bcv789', 'kubwin', 'hengmak168']
  const isCustomAgentPreset = customAgentList.find(agent => agent === config?.agent_name)

  if (isGameListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          {
            isLoggedIn && (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ pt: 3, pb: 0 }}
                >
                  <MainMenu />
                </Grid>
                {
                  isMobile && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{ pt: 3, pb: 0 }}
                    >
                    </Grid>
                  )
                }
              </>
            )
          }
          {groupedArray
            .filter((item) => {
              const match = {
                0: 'slot',
                1: 'casino',
                2: 'sport',
                3: 'lottery'
              } as any

              return item.category === match[category]
            })
            .map((item, index) => (
              <Grid
                id={`category-${item.category}`}
                key={`category-${item.category}`}
                item
                sx={{ pt: 0, pb: 0 }}
                width={'100%'}
              >

                <Grid container justifyContent={"center"}>
                  <Grid
                    item
                    xs={2}
                    md={1}
                    sx={{
                      position: "sticky",  // ทำให้ Grid ที่ห่อ GameMenu เป็น sticky
                      top: 80,              // sticky จากด้านบนของ viewport
                      alignSelf: "flex-start", // ทำให้แน่ใจว่า sticky จะทำงานตลอด
                    }}
                  >
                    <GameMenu category={category} setCategory={setCategory} />
                  </Grid>

                  <Grid
                    container
                    // marginLeft={1}
                    padding={1}
                    paddingLeft={2}
                    xs={10} 
                    md={11}
                    spacing={2}
                  >
                    {item.data.map((game) => (
                      <Grid key={game.code} item md={4} sm={3} xs={12} position="relative"  width={"100%"}>
                        <Box onClick={!isLoggedIn ? handleRightMenuClick : undefined}>
                          <Box
                            onClick={() => handleProviderClick(
                              game?.games_lobby?.[0]?.id || "",
                              game?.code || "",
                              game?.category || "",
                              game?.games_lobby?.[0]?.code || "",
                              game?.games_lobby?.[0]?.is_iframe ?? true,
                              game?.code_betflik || ""
                            )}
                            style={{ textDecoration: "none", color: theme.palette.primary.main, textAlign: "center" }}
                          >
                            <ItemImage
                              placeholder={"blur"}
                              blurDataURL="/assets/icons/category-casino.png"
                              // src={
                              //   "/assets/icons/category-casino.png"
                              // }
                              src={
                                `/assets/test_game/${game.category}/${game.name}.png`
                              }
                              // src={
                              //   `C:/lobby/next-platform/public/assets/test_game/${game.category}/${game.name}.png`
                              // }
                              // src={
                              //   isCustomAgentPreset
                              //     ? game.name === 'Dream Gaming' && config?.agent_name !== 'bcv789'
                              //       ? config?.agent_name !== 'kubwin'
                              //         ? game?.image
                              //         : `https://images-platform.sgp1.cdn.digitaloceanspaces.com/gamepartners/custom/${config?.agent_name}/${game.name}.png`
                              //       : `https://images-platform.sgp1.cdn.digitaloceanspaces.com/gamepartners/custom/${config?.agent_name}/${game.name}.png`
                              //     : game?.image || "/assets/icons/category-casino.png"
                              // }
                              width={250}
                              height={95}
                              alt={game.name}
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                background: '#08191f ',
                                borderRadius: 15,
                                border: `2px solid rgba(184,151,59,1)`,
                              }}
                            />
                            {/* <h3 style={{ margin: 0}}>{game.name}</h3> */}
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              
              </Grid>
            ))}
        </Grid>
      </Box>
    );
  }
}

"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Breadcrumbs, Link, Avatar, Stack, Typography } from "@mui/material";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination } from 'swiper/modules';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { KeyboardArrowRight, KeyboardArrowLeft, Loop } from "@mui/icons-material";
import { margin } from "@mui/system";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  
  color: theme.palette.text.secondary,
}));
function NextArrow(props: any) {
  const theme = useTheme(); 
  const { className, style, onClick } = props;
  return (
    <KeyboardArrowRight
      className={className}
      style={{ ...style, color: theme.palette.primary.main  , marginRight: '10px'}}
      onClick={onClick}
    />
  );
}

function PrevArrow(props: any) {
  const theme = useTheme(); 
  const { className, style, onClick } = props;
  return (
    <KeyboardArrowLeft
      className={className}
      style={{ ...style, color: theme.palette.primary.main , marginLeft: '10px'}}
      onClick={onClick}
    />
  );
}

export default function Footer() {
  const theme = useTheme();

  // Settings for the carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 7,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <Box component="footer" sx={{ flexGrow: 1, mt: "5px" }}>
      <Grid container spacing={0} pt={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        
        <Grid item xs={12} md={3} >
          <Box
            sx={{
              backgroundColor: 'rgba(0,0,0,0.8)',
              padding: 2,
              borderRadius: 1,
              height:"100%",
              boxShadow: 1,
              overflow: 'hidden',
            }}
          >
            <Item>
              <Typography mb={1} color={theme.palette.primary.main}>
                Casino คาสิโนออนไลน์ ที่ดีที่สุด
              </Typography>
              <Typography mb={1}>
                เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง
              </Typography>
            </Item>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 1, p: 0 }}>
          <Item sx={{ pt: 0, px: 0 }}>
            <Stack
              sx={{
                padding: 2,
                paddingBottom: 3,
                borderRadius: 1,
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                height:"100%",
                backgroundColor: 'rgba(0,0,0,0.8)',
              }}
            >
              <Typography
                mb={1}
                variant="h5"
                color={theme.palette.primary.main}
              >
                ธนาคารที่รองรับ
              </Typography>
              <Box sx={{ width: '100%' }}>
                <Slider {...settings}>
                  {[
                    "KBANK.png", "SCB.png", "KTB.png", "BAY.png",
                    "TRUEWALLET.png", "GSB.png", "BBL.png", "BAAC.png",
                    "KKB.png", "OSK.png", "TTB.png", "TISGO.png", 
                    "UOB.png", "CITI.png", "LNH.png", "CIMB.png", "TCR.png",
                    "MIZUHO.png", "SCBT.png", "ICBC.png", "ISBT.png"
                  ].map((img) => (
                    <div key={img}>
                      <Avatar
                        alt="Bank Logo"
                        src={`https://d13bpib24yechf.cloudfront.net/_ty1/${img}?auto=compress&q=50&fm=webp`}
                        sx={{ width: 50, height: 50 }}
                      />
                    </div>
                  ))}
                </Slider>
              </Box>
            </Stack>
          </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              backgroundColor: 'rgba(0,0,0,0.8)',
              borderRadius: 1,
              boxShadow: 1,
              overflow: 'hidden',
            }}
          >
            <Item>
            <Typography mb={1} variant="h5" color={theme.palette.primary.main}>
                TAGS
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,alignContent: 'center'}}>
              <Breadcrumbs separator="-" aria-label="breadcrumb" sx={{ justifyContent: 'center'}}>
                <Typography >เว็บตรง</Typography>
                <Typography >โปรโมชั่น</Typography>
                <Typography >เครดิตฟรี</Typography>
                <Typography >คืนยอดเสีย</Typography>
                <Typography >แนะนำเพื่อน</Typography>
                <Typography >กงล้อ</Typography>
                <Typography >นโยบายความเป็นส่วนตัว</Typography>
              </Breadcrumbs>
            </Box>
            </Item>
          </Box>
        </Grid>
    
        <Grid item xs={12} sx={{              
            backgroundColor: 'rgba(0,0,0,0.8)',
            padding: 2,
            borderRadius: 1,
            mt: 1,
          }}>
          <Item >
            <Typography mb={1} color={theme.palette.primary.main}>
              Terms and Conditions
            </Typography>
            <Typography mb={1}>
              Copyright © 2022 Casino All Rights Reserved.
            </Typography>          
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
"use client";

import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
} from "@mui/material";
import * as React from "react";
import { useAuthStore } from "@/stores/authStore";
import {
  ChatRounded,
  Home,
  LoginOutlined,
  Person,
  AccountBalanceWallet,
  CurrencyExchange,
  Campaign,
  Chat,
} from "@mui/icons-material";
import { usePathname, useRouter } from "next/navigation";
import { shallow } from "zustand/shallow";
import { useAppModalStore } from "@/stores/appModalStore";
import { useAuthModalStore } from "@/stores/authModalStore";
import useSiteSetting from "@/hooks/useSiteSetting";

export interface IAppProps {}

type BottomNavigationActionItem = {
  path: string;
  label: string;
  icon: React.ReactNode;
  actionType: "navigate" | "modal" | "external" ; 
};
 
const beforeLoginActions: BottomNavigationActionItem[] = [
  {
    path: "login",
    label: "เข้าสู่ระบบ",
    icon: <LoginOutlined />,
    actionType: "modal",
  },
  {
    path: "signUp",
    label: "สมัครสมาชิก",
    icon: <Person />,
    actionType: "modal",
  },
];

const afterLoginActions: BottomNavigationActionItem[] = [
  {
    path: "/",
    label: "หน้าแรก",
    icon: <Home />,
    actionType: "navigate",
  },
  {
    path: "promotion",
    label: "โปรโมชั่น",
    icon: <Campaign />,
    actionType: "modal",
  },
  {
    path: "/",
    label: "ฝาก-ถอน",
    icon: <CurrencyExchange />,
    actionType: "navigate",
  },
  {
    path: "/",
    label: "กระเป๋าตัง",
    icon: <AccountBalanceWallet />,
    actionType: "navigate",
  },
  {
    path: "Addline",
    label: "ติดต่อ",
    icon: <Chat  />,
    actionType: "external",
  },
];

export default function LayoutBottomNavigation(props: IAppProps) {
  const path = usePathname();
  const router = useRouter();
  const { lineOaUrl } = useSiteSetting();

  // Auth Modal
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );

  const [setOpenAppModal, setAppDataModal] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);

  const actions = isLoggedIn ? afterLoginActions : beforeLoginActions;

  const handleAction = (action: BottomNavigationActionItem) => {
    if (action.actionType === "modal") {
      if (action.path === "login") {
        setOpenLoginModal(true);
      } else {
        setAppDataModal(action.path);
        setOpenAppModal(true);
      }
    } else if (action.actionType === "navigate") {
      router.push(action.path);
    } else if (action.actionType === "external" && action.path === "Addline") {
      window.open(lineOaUrl, "_blank");
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        p: 0,
        m: 0,
      }}
    >
      <BottomNavigation
        sx={{
          background: (theme) => theme.gradient[400],
        }}
        showLabels
        value={activeIndex}
        onChange={(event, newValue) => {
          setActiveIndex(newValue);
        }}
      >
        {actions.map((action, index) => (
          <BottomNavigationAction
            key={index}
            label={action.label}
            icon={action.icon}
            onClick={() => handleAction(action)}
          />
        ))}
      </BottomNavigation>
    </Container>
  );
}

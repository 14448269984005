"use client";

import * as React from "react";
import { parseISO, format } from "date-fns";
import {
    Box,
    Button,
    FormControl,
    Typography,
    Divider,
    Card,
    Grid,
    Chip,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    CircularProgress,
    Stack,
    Snackbar,
    useTheme,
    useMediaQuery,
} from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useQRCode } from "next-qrcode";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import useUserReferral from "@/hooks/fetchers/useUserReferral";
import useUserAffiliate from "@/hooks/fetchers/useUserAffiliate";
import useReferralHistory from "@/hooks/fetchers/useReferralHistory";
import { useSnackbar } from "notistack";
import { useAffilateStore } from "@/stores/affilateStore";
import useMe from "@/hooks/fetchers/useMe";
import numeral from "numeral";

type Props = {};

const AffilateMonthly = (props: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { refresh } = useMe();
    const [accept] = useAffilateStore((state) => [state.accept], shallow);
    const [user] = useAuthStore((state) => [state.user], shallow);
    const { data: affiliatelist, isLoading: isAffiliateListLoading } = useUserAffiliate();
    const { enqueueSnackbar } = useSnackbar();
    const { Image } = useQRCode();

    //   function claimAffilate() {
    //     if (
    //       referrallist?.sum_money != undefined &&
    //       parseFloat(referrallist?.sum_money) > 0
    //     ) {
    //       accept()
    //         .then((res: any) => {
    //           if (res?.errMessage) {
    //             enqueueSnackbar(res.errMessage, {
    //               variant: "error",
    //               anchorOrigin: {
    //                 vertical: "top",
    //                 horizontal: "right",
    //               },
    //             });
    //           } else {
    //             enqueueSnackbar("รับค่าคอมมิชชั่นสำเร็จ", {
    //               variant: "success",
    //               anchorOrigin: {
    //                 vertical: "top",
    //                 horizontal: "right",
    //               },
    //             });
    //             refresh();
    //           }
    //         })
    //         .catch((err) => {
    //           enqueueSnackbar("This is a success message!", {
    //             variant: "error",
    //             anchorOrigin: {
    //               vertical: "top",
    //               horizontal: "right",
    //             },
    //           });
    //         });
    //     } else {
    //       enqueueSnackbar("ไม่มีรายได้สำหรับรับค่าคอมมิชชั่น", {
    //         variant: "error",
    //         anchorOrigin: {
    //           vertical: "top",
    //           horizontal: "right",
    //         },
    //       });
    //     }
    //   }

    interface Column {
        id: "name" | "deposit" | "withdraw" | 'profit';
        label: string;
        minWidth?: number;
        align?: "right";
        format?: (value: number) => string;
    }

    const columns: readonly Column[] = [
        { id: "name", label: "ยูสเซอร์", minWidth: 60 },
        { id: "deposit", label: "กำไร", minWidth: 60, align: 'right' },
        // { id: "withdraw", label: "ยอดถอน", minWidth: 60 },
        // { id: "profit", label: "กำไร", minWidth: 60 },
    ];

    interface Data {
        name: string;
        deposit: string;
        withdraw: string;
        profit: string;
    }

    function createData(name: string, deposit: number, withdraw: number, profit: number,): Data {
        return {
            name,
            deposit: numeral(deposit).format("0,0.00"),
            withdraw: numeral(withdraw).format("0,0.00"),
            profit: numeral(profit).format("0,0.00")
        };
    }

    const rows: any[] = [];

    affiliatelist?.affiliateList?.map((item_history: any) =>
        rows.push(
            createData(
                item_history?.gameusername,
                item_history?.deposit,
                item_history?.withdraw,
                item_history?.profit,
            )
        )
    );

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    if (isAffiliateListLoading) {
        return (
            <>
                <Grid sx={{ textAlign: "center" }}>
                    <CircularProgress></CircularProgress>
                </Grid>
            </>
        );
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} paddingTop={'0 !important'}>
                    <Box display="flex" flexDirection={"row"} alignItems={'center'} justifyContent={'space-between'}>
                        <Box textAlign="center">
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                }}
                            >
                                รหัสแนะนำ
                            </Typography>
                        </Box>
                        <Box textAlign="center">
                            <Button
                                startIcon={<ContentCopyIcon />}
                                variant="contained"
                                onClick={() =>
                                    navigator.clipboard.writeText(affiliatelist?.code || "")
                                }
                            >
                                คัดลอก
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box textAlign="center">
                        <FormControl sx={{ width: "100%" }}>
                            <TextField
                                fullWidth
                                value={affiliatelist?.code || ""}
                                id="fullWidth"
                                size="small"
                            />
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Box display="flex" flexDirection={"row"} alignItems={'center'} justifyContent={'space-between'}>
                        <Box textAlign="center">
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                }}
                            >
                                ลิงก์แนะนำ
                            </Typography>
                        </Box>
                        <Box textAlign="center">
                            <Button
                                startIcon={<ContentCopyIcon />}
                                variant="contained"
                                onClick={() =>
                                    navigator.clipboard.writeText(affiliatelist?.affiliateURL || "")
                                }
                            >
                                คัดลอก
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box textAlign="center">
                        <FormControl sx={{ width: "100%" }}>
                            <TextField
                                fullWidth
                                value={affiliatelist?.affiliateURL || ""}
                                id="fullWidth"
                                size="small"
                            />
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={6} md={6}>
                    <Stack
                        sx={{
                            overflow: "hidden",
                            borderRadius: "16px",
                        }}
                        boxShadow={theme.shadows[8]}
                        mt={2}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{
                                p: 1,
                                textAlign: "center",
                                bgcolor: theme.mineColor["paper-soft"],
                            }}
                        >
                            จำนวนสมาชิกทั้งหมด
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.primary.main,
                                p: 1,
                                textAlign: "center",
                                bgcolor: theme.mineColor["paper-deep"],
                            }}
                            variant="subtitle1"
                            fontWeight={"bold"}
                        >
                            {numeral(affiliatelist?.affiliateCount).format("0,0")}
                            <Box
                                ml={0.5}
                                color={theme.palette.primary.main}
                                fontSize={16}
                                component={"span"}
                            >
                                คน
                            </Box>
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Stack
                        sx={{
                            overflow: "hidden",
                            borderRadius: "16px",
                        }}
                        boxShadow={theme.shadows[8]}
                        mt={2}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{
                                p: 1,
                                textAlign: "center",
                                bgcolor: theme.mineColor["paper-soft"],
                            }}
                        >
                            รายได้เดือนปัจจุบัน
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.primary.main,
                                p: 1,
                                textAlign: "center",
                                bgcolor: theme.mineColor["paper-deep"],
                            }}
                            variant="subtitle1"
                            fontWeight={"bold"}
                        >
                            {numeral(affiliatelist?.affiliateCommission).format("0,0.00")}
                            <Box
                                ml={0.5}
                                color={theme.palette.primary.main}
                                fontSize={16}
                                component={"span"}
                            >
                                บาท
                            </Box>
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Stack
                        sx={{
                            overflow: "hidden",
                            borderRadius: "16px",
                        }}
                        boxShadow={theme.shadows[8]}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{
                                p: 1,
                                textAlign: "center",
                                bgcolor: theme.mineColor["paper-soft"],
                            }}
                        >
                            เปอร์เซ็นต์
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.primary.main,
                                p: 1,
                                textAlign: "center",
                                bgcolor: theme.mineColor["paper-deep"],
                            }}
                            variant="subtitle1"
                            fontWeight={"bold"}
                        >
                            {affiliatelist?.affiliatePercentage}
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Box mt={2} textAlign="center">
                        <Divider>
                            <Chip
                                label="สรุปรายงานเดือนนี้"
                                color="primary"
                                size="medium"
                                style={{ fontSize: "16px", fontWeight: 500 }}
                            />
                        </Divider>
                    </Box>
                    <Box mt={2} textAlign="center">
                        <Paper sx={{ width: "100%", overflow: "hidden" }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    sx={{ minWidth: column.minWidth, color: (theme) => theme.gradient[700], bgcolor: (theme) => theme.gradient[610] }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row['name']}
                                                    >
                                                        {columns.map((column) => {
                                                            const value = row[column.id];

                                                            if (column.id === 'name') {
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === "number"
                                                                            ? column.format(value)
                                                                            : value}
                                                                    </TableCell>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        <Box
                                                                            display="flex"
                                                                            flexDirection="column"
                                                                        >
                                                                            <Typography variant="inherit" fontSize={'0.75rem'} color="#00be7a" fontWeight={'700'}>
                                                                                {row['profit']}
                                                                            </Typography>
                                                                            <Typography variant="inherit" fontSize={'0.65rem'} color='white'>
                                                                                {`ฝาก: ${row['deposit']}`}
                                                                            </Typography>
                                                                            <Typography variant="inherit" fontSize={'0.65rem'} color='white'>
                                                                                {`ถอน: ${row['withdraw']}`}
                                                                            </Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                );
                                                            }
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={'Rows:'}
                                labelDisplayedRows={({ from, to, count }) => isMobile ? `Page: ${page + 1}/${Math.ceil(rows.length / rowsPerPage)}` : `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}
                            />
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        );
    }
};

export default AffilateMonthly;

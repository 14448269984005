import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR from "swr";
import { shallow } from "zustand/shallow";

type useUserAffiliateReturn = {
  data: any | null;
  isLoading: boolean;
  isError: any;
};

export default function useUserAffiliate(): useUserAffiliateReturn {
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const { data, error } = useSWR<useUserAffiliateReturn>(
    isLoggedIn ? `/api/v2/users/affiliate` : null,
    fetcherAuth
  );

  return {
    data: data?.data || null,
    isLoading: !error && !data,
    isError: error,
  };
}
